@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
html {
  scroll-behavior: smooth;
}

#root {
  background-color: #f0f0f0;
  min-width: 1000px;
}

.MuiButtonBase-root.MuiListItemButton-root.Mui-selected {
  background-color: rgba(25, 118, 210, 0.4) !important;
}

.MuiPaper-root.MuiCard-root.project-card {
  /* box-shadow: 5px 5px 5px 5px chocolate !important; */
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 200, 0.2);
  animation: project-shadow 2s ease-in-out infinite;
}

@keyframes project-shadow {
  50% {
    box-shadow: 1px 1px 20px 2px rgba(42, 42, 65, 0.2);
  }
  100% {
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 200, 0.2);
  }
}
